import { BrowserRouter } from 'react-router-dom';
import { AppThemeProvider } from './theme';
import { AppSnackBarProvider } from './components/AppSnackBar';
import Routes from './routes';
import Layout from './layout';
import { ErrorBoundary } from './components';
import './App.css';

/**
 * Root Application Component
 * @component App
 */
const App = () => {
  return (
    <ErrorBoundary name="App">
      <AppThemeProvider>
        <AppSnackBarProvider>
          <BrowserRouter>
            <Layout>
              <Routes />
            </Layout>
          </BrowserRouter>
        </AppSnackBarProvider>
      </AppThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
