import React from 'react';
/**
 * Note: Don't import/export all Views directly, use lazy loading!
 */
import { withSuspense } from '../components';
import NotFoundView from './NotFoundView';
import NotImplementedView from './NotImplementedView';
import { RedirectArtist } from './RedirectArtist';
const ArtistProfilePage = withSuspense(React.lazy(() => import('./ArtistProfile')));
const ArtistListPage = withSuspense(React.lazy(() => import('./ArtistList')));

export { NotFoundView, NotImplementedView, ArtistProfilePage, ArtistListPage, RedirectArtist };
