import { PALETTE_COLORS } from './colors';
import { TYPOGRAPHY } from './typography';

/**
 * MUI theme options for "Dark Mode"
 */
export const DARK_THEME = {
  palette: {
    mode: 'dark',
    background: {
      paper: '#161718', // Gray 800 - Background of "Paper" based component
      default: '#000000',
    },
    ...PALETTE_COLORS,
  },
  typography: TYPOGRAPHY,
};

export default DARK_THEME;
