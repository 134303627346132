import { SHARED_CONTROL_PROPS } from '../../utils/form';
import { TextField } from '@mui/material';

const AppTextField = ({
  children,
  label,
  fieldLabel,
  name,
  required,
  value,
  borderRadius,
  onFieldChange = () => {},
  fieldHasError = () => false,
  fieldGetError = () => '',
  sx: propSx,
  ...restOfProps
}) => {
  const DEFAULT_SX_VALUES = {
    // '& label.Mui-focused': {
    //   color: 'white',
    // },
    // '& .MuiInput-underline:after': {
    //   borderBottomColor: 'white',
    // },
    // '& .MuiInputLabel-root': {
    //   color: 'white',
    // },
    // '& .MuiOutlinedInput-input': {
    //   color: 'white',
    // },
    // '& .MuiInputAdornment-root': {
    //   color: 'white',
    // },
    marginTop: '4px',
    '& .MuiOutlinedInput-root': {
      backgroundColor: (theme) => theme.palette.background.paper,
      '& fieldset': {
        // borderColor: (theme) => theme.palette.background.default,
        border: '1px solid gray',
        borderRadius: borderRadius ?? '20px',
        // backgroundColor: (theme) => theme.palette.background.default,
      },
      '&:hover fieldset': {
        borderColor: 'secondary',
        borderWidth: '1px',
        borderRadius: borderRadius ?? '20px',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'secondary',
        borderWidth: '1px',
        borderRadius: borderRadius ?? '20px',
      },
    },
  };

  return (
    <>
      {fieldLabel && <label htmlFor={name}>{fieldLabel}</label>}
      <TextField
        {...SHARED_CONTROL_PROPS}
        required={required}
        label={label}
        name={name}
        value={value}
        error={fieldHasError(name)}
        helperText={fieldGetError(name) || ''}
        onChange={onFieldChange}
        inputProps={{ style: { height: '27px' } }}
        sx={{
          ...DEFAULT_SX_VALUES,
          ...propSx,
        }}
        {...restOfProps}
      />
    </>
  );
};

export default AppTextField;
