import { Route, Routes } from 'react-router-dom';
import { ArtistListPage, ArtistProfilePage, RedirectArtist } from '../pages';

/**
 * List of routes available only for anonymous users
 * @component PublicRoutes
 */
const PublicRoutes = () => {
  return (
    <Routes>
      <Route exact path="/:name" element={<RedirectArtist />} />
      <Route path="/" element={<ArtistListPage />} />
      <Route path="/admin/home" element={<ArtistListPage admin={true} />} />
      <Route path="/artist/:id" element={<ArtistProfilePage />} />
    </Routes>
  );
};

export default PublicRoutes;
