const H1 = {
  fontFamily: '"Mulish", sans-serif',
  fontSize: '40px',
  fontWeight: 600,
  lineHeight: '60px',
};

const H2 = {
  fontFamily: '"Mulish", sans-serif',
  fontSize: '32px',
  fontWeight: 600,
  lineHeight: '48px',
};

const H3 = {
  fontFamily: '"Mulish", sans-serif',
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '30px',
};

const H4 = {
  fontFamily: '"Mulish", sans-serif',
  fontSize: '15px',
  fontWeight: 600,
  lineHeight: '22.5px',
};

const H5 = {
  fontFamily: '"Mulish", sans-serif',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '21px',
};

const H6 = {
  fontFamily: '"Mulish", sans-serif',
  fontSize: '10px',
  fontWeight: 400,
  lineHeight: '12px',
};

const subtitle1 = {
  fontFamily: '"Mulish", sans-serif',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '24px',
};

const subtitle2 = {
  fontFamily: '"Mulish", sans-serif',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16px',
};

const body1 = {
  fontFamily: '"Mulish", sans-serif',
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '20px',
};

const body2 = {
  fontFamily: '"Mulish", sans-serif',
  fontSize: '10px',
  fontWeight: 400,
  lineHeight: '13px',
};

export const TYPOGRAPHY = {
  htmlFontSize: 14,
  fontFamily: '"Mulish", sans-serif',
  fontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  subtitle1: subtitle1,
  subtitle2: subtitle2,
  body1: body1,
  body2: body2,
};
