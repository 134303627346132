import { Logtail } from '@logtail/browser';
import { insertLogHistory } from '../supabase/log';

const logtail = new Logtail(process.env.REACT_APP_BETTER_STACK_ID);

export const insert = async (message, data = {}, type = 'info') => {
  if (!message) return;
  if (type === 'info') {
    logtail.info(message, data);
    await insertLogHistory({ ...data, message });
  } else if (type === 'error') {
    logtail.error(message, data);
  }
};
