import { light } from '@mui/material/styles/createPalette';

const COLOR_PRIMARY = {
  main: '#4064F4',
  contrastText: '#ffffff',
};

const COLOR_SECONDARY = {
  main: '#65D4ED',
  contrastText: '#ffffff',
};

const COLOR_INFO = {
  main: '#ffffff', // Light Blue 800
  contrastText: '#FFFFFF',
};

const COLOR_SUCCESS = {
  main: '#2e7d32',
  contrastText: '#FFFFFF',
};

const COLOR_WARNING = {
  main: '#f9a825',
  // contrastText: '#000000',
  contrastText: '#FFFFFF',
};

const COLOR_ERROR = {
  main: '#c62828',
  contrastText: '#FFFFFF',
};

/**
 * MUI colors set to use in theme.palette
 */
export const PALETTE_COLORS = {
  primary: COLOR_PRIMARY,
  secondary: COLOR_SECONDARY,
  info: COLOR_INFO,
  success: COLOR_SUCCESS,
  warning: COLOR_WARNING,
  error: COLOR_ERROR,
  text: {
    primary: '#FFFFFF',
    secondary: '#737474',
    disabled: '#9A9A9A',
  },
};
