import ReactGA from 'react-ga4';

export const initGA = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_KEY, {
    gaOptions: { streamId: process.env.REACT_APP_GA_STREAM_ID },
  });
};

export const sendPageData = (type, page, title) => {
  ReactGA.send({
    hitType: type,
    page,
    title,
  });
};

export const sendEventData = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};
