import * as logs from './logs';
import * as supabase from './supabase';
import * as ip from './ip';
import * as GA from './google-analytics';

const api = {
  GA,
  log: logs,
  db: supabase,
  ip,
};

export default api;
