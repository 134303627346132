import React from 'react';
import { Stack } from '@mui/material/';
import { useLocation } from 'react-router-dom';
import { ErrorBoundary } from '../components';
import { TOPBAR_DESKTOP_HEIGHT } from './config';
import TopBar from './TopBar';
import LogoIcon from '../assets/logo/avmainlogo.png';
import FooterLogoIcon from '../assets/logo/avfooter1.png';

// TODO: change to your app name or other word
const TITLE_PUBLIC = 'ARTISTVERIFIED'; // Title for pages without/before authentication

/**
 * Renders "Public Layout" composition
 * @component PublicLayout
 */
const PublicLayout = ({ children }) => {
  const location = useLocation();

  const showTopbar = location.pathname === '/' || location.pathname === '/admin/home';
  const title = TITLE_PUBLIC;
  document.title = title; // Also Update Tab Title

  return (
    <Stack
      sx={{
        minHeight: '100vh', // Full screen height
        paddingTop: showTopbar ? TOPBAR_DESKTOP_HEIGHT : 0,
      }}
    >
      {showTopbar && (
        <TopBar
          startNode={<img src={LogoIcon} width={'25'} height={'25'} alt="logo" />}
          title={title}
        />
      )}

      <Stack
        component="main"
        sx={{
          flexGrow: 1, // Takes all possible space
          padding: showTopbar ? 1 : 0,
          marginTop: showTopbar ? 1 : 0,
        }}
      >
        <ErrorBoundary name="Content">{children}</ErrorBoundary>
      </Stack>
      <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} margin={3}>
        <img
          src={FooterLogoIcon}
          width={'160'}
          height={'35'}
          className="App-logo"
          alt="footerlogo"
        />
        <br />
        Copyright &copy; 2023-2024 ArtistVerified Inc.
      </Stack>
    </Stack>
  );
};

export default PublicLayout;
