import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PublicRoutes from './PublicRoutes';
import { api } from '../api';

/**
 * Renders Application routes depending on Logged or Anonymous users
 * @component AppRoutes
 */
const AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    api.GA.initGA();
  }, []);

  useEffect(() => {
    api.GA.sendPageData('pageview', location.pathname, location.pathname);
  }, [location]);

  return <PublicRoutes />;
};

export default AppRoutes;
