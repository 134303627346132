import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import api from '../../api';
import Loading from '../../components/Loading';

export const RedirectArtist = () => {
  const { name } = useParams();
  const [artistId, setArtistId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArtistId = async () => {
      const result = await api.db.artists.getArtistIdByVanityName(name);
      if (result?.rtsvrf_artist_id && result?.rtsvrf_profile_claim_status) {
        setArtistId(result?.rtsvrf_artist_id);
      }
      setLoading(false);
    };

    fetchArtistId();
  }, [name, artistId]);

  if (loading) {
    // Handle loading state
    return <Loading />;
  } else if (!artistId && !loading) {
    // Handle invalid artist name
    return <Navigate to={`/`} replace />;
  } else {
    return <Navigate to={`/artist/${artistId}`} replace />;
  }
};
